import { template as template_8ebe4ada12b04ccf8ba0c8ce375f0bcd } from "@ember/template-compiler";
const FKText = template_8ebe4ada12b04ccf8ba0c8ce375f0bcd(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
